.roll-in-out {
  position: relative;
  color: black;
  text-decoration: none;
}

.roll-in-out::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: currentColor;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
  -webkit-transition: transform 250ms ease-in;
  -moz-transition: transform 250ms ease-in;
  -ms-transition: transform 250ms ease-in;
  -o-transition: transform 250ms ease-in;
}

.roll-in-out:hover::after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform-origin: left;
}

.signature:nth-child(1) {
  stroke-dasharray: 3px;
}

.signature:nth-child(2) {}

.signature:nth-child(3) {}

.signature:nth-child(4) {}