#wrapper {
  position: relative;
  width: 100%;
  min-height: 51.2vw;
  overflow: hidden;
}

.layer {
  position: absolute;
  width: 100vw;
  min-height: 51.2vw;
  overflow: hidden;
}

.layer .content-wrap {
  position: absolute;
  width: 100vw;
  min-height: 51.2vw;
}

.layer .content-body {
  width: 25%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}

.layer img {
  position: absolute;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.layer h1 {
  font-size: 2em;
}

.bottom {
  background: var(--primary_color);
  z-index: 1;
}

.bottom .content-body {
  right: 5%;
}

.top {
  background: var(--accent_color);
  z-index: 2;
  width: 50vw;
}

.top .content-body {
  left: 5%;
}

.content-body {
  z-index: 3;
}

.handle {
  position: absolute;
  height: 100%;
  display: block;
  background: var(--secondary_color);
  width: 0px;
  top: 0;
  left: 50%;
  z-index: 3;
}

.skewed .handle {
  top: 49%;
  transform: rotate(25deg) translateY(-50%);
  height: 200%;
  transform-origin: top;
}

.skewed .top {
  transform: skew(-25deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);
}

.skewed .top .content-wrap {
  transform: skew(25deg);
  margin-left: 1000px;
}

.logo {
  position: absolute;
  z-index: 4;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  height: 15%
}

.logo path {
  fill: var(--secondary_color) !important;
}

.logo text {
  fill: var(--secondary_color) !important;
}