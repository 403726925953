@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Roboto:100,300,400,500,700,900&display=swap');


:root {
	--title_color: rgb(193, 151, 95);
	--main_color: rgb(255, 255, 255);
	--primary_color: #c0c4f5;
	--secondary_color: #191e3a;
	--accent_color: #5f6792;
	--bg_color: #9999a1;
	overflow: auto;
	scroll-snap-type: y mandatory;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body,
html {
	background-color: var(--secondary_color);
	color: var(--primary_color);
	max-width: 100%;
	min-height: 100vh;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.color_primary {
	color: var(--primary_color);
	transition: all ease-in 0.3s;
}

.color_secondary {
	color: var(--secondary_color);
	transition: all ease-in 0.3s;
}

.color_accent {
	color: var(--accent_color);
	transition: all ease-in 0.3s;
}

.hover_color_primary:hover {
	color: var(--primary_color);
	transition: all ease-in 0.3s;
}

.hover_color_secondary:hover {
	color: var(--secondary_color);
	transition: all ease-in 0.3s;
}

.hover_color_accent:hover {
	color: var(--accent_color);
	transition: all ease-in 0.3s;

}

.roboto {
	font-family: 'Roboto';
}

.lato {
	font-family: 'Lato';
}

.hover-bg-accent:hover {
	background-color: var(--accent_color);
	transition: all ease-in 0.3s;
	-webkit-transition: all ease-in 0.3s;
	-moz-transition: all ease-in 0.3s;
	-ms-transition: all ease-in 0.3s;
	-o-transition: all ease-in 0.3s;
}

.hover-bg-primary:hover {
	background-color: var(--primary_color);
	transition: all ease-in 0.3s;
	-webkit-transition: all ease-in 0.3s;
	-moz-transition: all ease-in 0.3s;
	-ms-transition: all ease-in 0.3s;
	-o-transition: all ease-in 0.3s;
}































@-webkit-keyframes swing {
	15% {
		-webkit-transform: translateX(5px);
		transform: translateX(5px);
	}

	30% {
		-webkit-transform: translateX(-5px);
		transform: translateX(-5px);
	}

	50% {
		-webkit-transform: translateX(3px);
		transform: translateX(3px);
	}

	65% {
		-webkit-transform: translateX(-3px);
		transform: translateX(-3px);
	}

	80% {
		-webkit-transform: translateX(2px);
		transform: translateX(2px);
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes swing {
	15% {
		-webkit-transform: translateX(5px);
		transform: translateX(5px);
	}

	30% {
		-webkit-transform: translateX(-5px);
		transform: translateX(-5px);
	}

	50% {
		-webkit-transform: translateX(3px);
		transform: translateX(3px);
	}

	65% {
		-webkit-transform: translateX(-3px);
		transform: translateX(-3px);
	}

	80% {
		-webkit-transform: translateX(2px);
		transform: translateX(2px);
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.swing {
	-webkit-animation: swing 1s ease;
	animation: swing 1s ease;
	/* -webkit-animation-iteration-count: 1; */
	/* animation-iteration-count: 1; */
}